import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import BlockContent from "../components/sanity/blockContent"

// IndexPage is declerad as a function(al) component, React also supports class based components with proper constructors
// see https://reactjs.org/docs/components-and-props.html
const IndexPage = ({ data }) => {
  // This keeps the rest of the code cleaner
  // Without this we would have to call data.sanity.<someProp> instead of just index.<someProp>
  const index = data.sanityFrontPage

  return (
    <Layout>
      <SEO title="Home" />
      <h1>{index.title}</h1>
      {/* BlockContent uses the Sanity block content component with Funka's custom serializer

         the line "index._rawBody &&"" is a React/JS ternary operator, basically a if-else statement. 
         We could also write traditional if else statements as functions or consts abouve and call them like you would call any normal function in here
         And simply run the function inside the return (where this text is)
         If we don't validate if data exists before running components the site will crash of something calls data that returns Null
    */}
      {index._rawBody && <BlockContent blocks={index._rawBody} />}

      {/* 
        We can call fixed or fluid images, fluid images automatically scale to their parent container 
        Fixed images have a fixed height/width
    */}
      {index.mainImage.asset && (
        <Img fixed={index.mainImage.asset.fixed} alt={index.mainImage.alt} />
      )}
      <Link to="/page-2/">Go to page 2</Link>
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  {
    sanityFrontPage {
      _rawBody
      title
      mainImage {
        alt
        asset {
          fixed(width: 900) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`
